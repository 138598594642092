.language-selector{
    justify-content: flex-start;
}

.dropbtn {
    background: rgba(0,0,0,0.2);
    color: white;
    padding: 10px;
    font-size: 16px;
    border: solid 1px rgba(0,0,0,0.05);
    box-shadow: 5px 5px 5px lightblue;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    right: 0;    
    width: 100%;
    overflow: auto;
  }
  
  .language-title{
    padding: 10px 0;
    color: black;
    background-color: lightblue;
    text-shadow: 1px 1px 2px papayawhip, 0 0 1em lightblue, 1px 1px 0.5em blue;
  }

  .language-title > h2 {
   
  }
  /* Links inside the dropdown */
  .dropdown-content li {
    color: black;
    text-decoration: none;
    font-size: 24px;
    display: block;
  }
  .dropdown-country{
    width: 100%;
    padding: 12px 0;
    border: none;
    cursor: pointer;
  }
  /* Change color of dropdown links on hover */
  .dropdown-content>li>button:hover {
    background-color: hsl(216, 91%, 50%);
    }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: lightblue;
    color: black;
  }