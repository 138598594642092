body, html {
    height: 100%;
  }

.parallax {
    background-image: url('../assets/images/space.jpg');
    min-height: 75vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .parallax-layer {
    margin: auto;
  }
  .about-header{
    width: 80%;
    margin: 5% auto 0 auto;    
    min-height: 10vh;
    text-align: center;
    padding-top: 1.5%;
    font-size: 72px;
    font-weight: 700;
    color: black;
    text-shadow: 3px 3px 3px papayawhip, -3px -3px 3px white;
  }
  h2{
    font-size: 32px;
  }
  p{
    margin: 4% 2% 2% 2%;
    padding-bottom: 2%;
    letter-spacing: .25%;
    font-size: 1.25rem;
  }
  .div1 {
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 5%;
    padding-top: 3%;
    min-height: 50vh;
  }
  .div2{
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 50vh;
    margin-top: 10%;
    padding-top: 50px;
  }
  .div3{
    background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 50vh;
    margin-bottom: 5%;
    padding-top: 50px;
  }